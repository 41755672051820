<template>
  <v-dialog
    v-model="value"
    max-width="600px"
    scrollable
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h3 lighten-2 pb-5"
      >
        POLÍTICA DE COOKIES E REGISTRO DE NAVEGAÇÃO
      </v-card-title>
      <v-divider />
      <v-card-text>
        <br><h3>1. INTRODUÇÃO</h3>
        A Prefeitura Municipal de Mogi Guaçu, pessoa jurídica inscrita no CNPJ
        sob o nº 45.301.264/0001-13, tem o compromisso com a segurança e a privacidade
        das informações dos usuários do site, respeitando as previsões legais. Entendemos
        como sensível e relevante os registros eletrônicos e dados pessoais deixados pelo
        usuário na utilização dos diversos sites e serviços (Aplicativos), servindo a
        presente Política de Cookies e Registro de Navegação para regular, de forma simples,
        transparente e objetiva, quando e por que podemos enviar cookies aos visitantes da
        plataforma e como fazemos a gestão da navegação do usuário.<br>
        <br><h3>2. Dados Coletados, Finalidade e Legalidade</h3>
        Essa seção explica o que que dados coletamos e como usamos seus dados.<br>
        <br><b>2.1. Finalidade</b><br>
        A Secretaria de Cultura de Mogi Guaçu utiliza os dados coletados nesse site
        apenas para fins estatisticos, nenhum dado pessoal alem dos informados abaixo são coletados.
        Os dados são anonimizados para fins estatísticos.<br>
        Os são tratados pelo Departamento de Tecnologia e não são compartilhados com
        nenhum outro secretaria, departamento, setor, divisão ou empresa.<br>
        <br><b>2.1. Legalidade</b><br>
        Os dados são necessários para que a Prefeitura por meio da Secretaria de Cultura
        com o objetivo de continuamente melhorar os serviços prestados.<br>
        <br><h3>3. COOKIES</h3>
        Essa seção explica o que são e para que servem os cookies.<br>
        <br><b>3.1.1. O que é um cookie</b><br>
        Os “cookies” são arquivos de texto que um site ou aplicativo transfere para o disco
        rígido de uma pessoa ou outro equipamento de navegação, para fins de
        manutenção de registros. Os cookies permitem que a plataforma guarde
        informações importantes que tornarão mais prática a sua utilização. Um cookie
        geralmente contém o nome do domínio de origem, a “duração” e um número ou
        outro valor exclusivo gerado aleatoriamente. Alguns cookies serão utilizados na
        plataforma, independentemente de você ter ou não acessado ou autenticado sua
        conta.<br>
        <br><b>3.1.2. Pra que servem os cookies</b><br>
        Quando visitamos um site ou aplicativo, este pode armazenar ou recolher
        informações no seu navegador, principalmente na forma de cookies. Esta
        informação pode ser sobre si, as suas preferências ou do seu dispositivo e é
        utilizada principalmente para fazer a plataforma funcionar conforme o esperado. A
        informação normalmente não o identifica diretamente, mas pode dar-lhe uma
        experiência web mais personalizada. Os cookies servem para:<br><br>
        <li>Objetivos Analíticos;</li>
        <li>Monitoramento de indicadores;</li>
        <li>Finalidades de testes, usabilidade e melhoria do desempenho;</li>
        <li>Finalidades de segurança;</li>
        <li>Prevenção de fraude/spam;</li>
        <li>Conformidade.</li>
        <br>Sendo assim, a finalidade para uso dessas informações é fornecer, melhorar e
        desenvolver os serviços disponíveis na plataforma.
        <br><br>
        <b>3.1.3. Tipos de cookies usados</b><br>
        Usamos cookies e tecnologias semelhantes para fornecer e apoiar nossos
        serviços, através de personalização do conteúdo compartilhado, para garantir que
        o conteúdo da plataforma seja relevante.<br>
        Uma vez que respeitamos o seu direito à privacidade, pode optar por não permitir
        alguns tipos de cookies. No entanto, o bloqueio deles pode afetar a sua experiência
        na plataforma e os serviços que podemos oferecer. Os cookies tradicionais usados
        pela plataforma são:
        <br><br><b>3.1.3.1. Cookies de sessão</b><br>
        Os cookies de sessão são temporários e permanecem no arquivo de cookies
        do seu navegador até você sair da plataforma.<br>
        Quando utilizamos cookies de sessão para registrar o número total de
        visitantes da plataforma, isso é feito de forma agregada e anônima (já que os
        cookies não contêm dados pessoais). Poderemos também utilizar cookies
        para que possamos nos lembrar de seu computador quando ele for utilizado
        novamente na plataforma, ajudando a personalizar sua experiência na web.
        Nesses casos, podemos associar informações pessoais a um arquivo de
        cookies.
        <br><br><b>3.1.3.2. Cookies persistentes</b><br>
        Os cookies persistentes permanecem no arquivo de cookies do seu
        navegador durante muito mais tempo, ou seja, até atingirem sua data de
        validade ou até serem excluídos manualmente.
        <br><br><b>3.1.3.3. Cookies de terceiros</b><br>
        Os cookies de terceiros podem ser cookies de sessão ou persistentes.<br>
        Por questão de monitoramento utilizamos Cookies do GOOGLE Analytics, eles são
        essenciais para o monitoramento e analise do funcionamento do sistema.<br>
        A Política de Privacidade do Google Analytics pode ser acessada aqui:
        <a
          href="https://policies.google.com/privacy?hl=pt_BR"
          target="_blank"
        >
          https://policies.google.com/privacy?hl=pt_BR
        </a>
        <br><br><h3>4. GESTÃO DOS COOKIES NO SEU NAVEGADOR</h3>
        Você pode eliminar e gerenciar facilmente os cookies instalados na pasta de cookies
        do seu navegador seguindo as instruções fornecidas pelo fabricante.<br>
        Você pode também consultar a documentação do seu dispositivo móvel para obter
        informações sobre como desativar os cookies. Se desativar todos os cookies, talvez
        você não consiga aproveitar todos os recursos da plataforma. Observe que se você
        não eliminar os cookies ou a memória cache, os conteúdos deles poderão afetar as
        funções de preenchimento automático a você e gerar possíveis dificuldades na
        operação do aplicativo.
        <br><br><h3>5. REGISTRO DE NAVEGAÇÃO</h3>
        A plataforma registra a navegação dos usuários através do IP fornecido no momento
        da consulta ao site ou aplicativo.<br>
        Registramos dados de sua visita à plataforma através de cookies e outras tecnologias
        de rastreamento incluindo seu endereço IP, a versão do seu
        navegador e do seu sistema operacional, dados de tráfego online, dados de
        localização, logs da web e outros dados de navegação.
        <b>
          Esses dados são coletados no momento do acesso e descartados imediatamente após
          serem anonimizados para o uso estatístico.
        </b>
        <br><br><h3>6. ALTERAÇÃO À NOSSA POLÍTICA DE COOKIES E REGISTRO DE NAVEGAÇÃO</h3>
        Buscando uma melhoria contínua, a Prefeitura Municipal de Mogi Guaçu reserva o direito de alterar esta Política de
        Cookies e Registro de Navegação a qualquer momento, mediante publicação da
        versão atualizada neste site.
        <br><br><h3>7. COMO ENTRAR EM CONTATO CONOSCO</h3>
        Perguntas, comentários ou reclamações sobre a política entre em contato conosco
        através do e-mail: <a href="mailto:privacidade@mogiguacu.sp.gov.br">privacidade@mogiguacu.sp.gov.br</a>.<br>
        Esse email é excluvivo para tratar de assuntos relacionados à Política de Privacidade.
        Você pode solicitar alteração ou exclusão de algum dado pessoal pelo mesmo email.
        <br><br>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-center">
        <v-btn
          color="blue lighten-1"
          @click="$emit('fecharAlerta')"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'LgpdDialog',
    props: {
      value: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
  }
</script>
